.App {
  font-family: "Roboto Condensed", sans-serif;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

a {
  text-decoration: none;
}
