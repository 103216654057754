nav {
  display: flex !important;
  flex-direction: column;
}

nav a {
  color: black;
  font-family: "Flamenco", cursive;
  font-size: 1.6rem;
  padding: 6px;
}

@media only screen and (max-width: 730px) {
  nav a {
    border: 2px solid grey;
    box-shadow: 3px 4px 5px 0px grey;
    border-radius: 10px;
    width: 250px;
    text-align: center;
  }

  nav a:nth-last-child(1) {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 730px) {
  nav {
    flex-direction: row;
    justify-content: center;
  }

  nav a {
    font-size: 1.2rem;
    margin: 0 10px;
  }
}

@media only screen and (min-width: 1275px) {
  nav a {
    font-size: 1.5rem;
  }

  nav a:hover {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 1900px) {
  nav a {
    font-size: 1.8rem;
    margin: 0 30px;
  }
}
