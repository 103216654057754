#about-page {
  background-color: #e7e7e7;
  overflow-x: hidden;
  position: relative;
}

.about-title {
  bottom: 265px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.9rem;
  font-weight: bold;
  padding-left: 10px;
  position: absolute;
  z-index: 1;
}

.about-title p {
  line-height: 0.8;
}

.worker-imgs {
  position: relative;
  top: 150px;
}

#office-manager {
  margin-left: 15px;
  width: 45vw;
}

#work-force {
  position: absolute;
  margin-top: 70px;
  margin-left: -80px;
  width: 70vw;
  z-index: 1;
}

.about-paragraph {
  background-color: white;
  border: 3px solid #3d4d43;
  border-radius: 60px;
  box-shadow: 6px 8px 0px 0px #3d4d43;
  color: #3d4d43;
  font-size: 1.143rem;
  position: absolute;
  text-align: center;
  z-index: 1;
}

#about-p1 {
  bottom: 590px;
  padding: 20px;
}

#about-p2 {
  bottom: 130px;
  font-size: 1rem;
  padding: 10px 20px;
}

.leased {
  background-image: url("https://res.cloudinary.com/tca7lsohuid8/image/upload/v1642306845/lcr_website/Leased_wfvpo6.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 145%;
  bottom: 0;
  height: 600px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.leased #marin-badge,
.leased #nc-badge {
  bottom: 10px;
  position: absolute;
  z-index: 1;
}

.leased #marin-badge {
  left: 50%;
  width: 50vw;
}

.leased #nc-badge {
  left: 10%;
  width: 30vw;
}

.about-title p {
  overflow: hidden;
  white-space: nowrap;
}

#title1 {
  animation: typing 3s steps(40, end), 0.75s 0 normal;
  top: 325px;
}

#title2 {
  animation: typing 3.5s steps(40, end), 1.75s 0 normal;
  top: 390px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media only screen and (min-width: 370px) {
  #about-p1 {
    right: 2%;
    left: 2%;
  }

  .about-title {
    bottom: 265px;
  }

  .leased {
    background-size: 140%;
  }
}

@media only screen and (min-width: 410px) {
  .leased {
    background-size: 130%;
  }
}

@media only screen and (min-width: 475px) {
  #about-p1 {
    bottom: 625px;
    right: 3%;
    left: 3%;
  }

  #work-force {
    width: 65vw;
  }

  .leased {
    background-size: 112%;
  }
}

@media only screen and (min-width: 595px) {
  #about-p1 {
    bottom: 675px;
    left: 15%;
    right: 15%;
  }

  #work-force {
    width: 64vw;
  }

  #about-p2 {
    bottom: 130px;
    font-size: 1.143rem;
    left: 10%;
    right: 10%;
  }

  .about-title p {
    font-size: 2.2rem;
    margin-left: 10px;
  }

  .leased {
    background-size: 100%;
    background-position-y: 225px;
  }

  .leased #marin-badge {
    bottom: 0px;
    width: 30vw;
  }

  .leased #nc-badge {
    bottom: 0px;
    left: 20%;
    width: 20vw;
  }
}

@media only screen and (min-width: 715px) {
  #about-p1 {
    bottom: unset;
    left: 20%;
    right: 20%;
    top: 225px;
  }

  #work-force {
    width: 60vw;
  }

  #about-p2 {
    left: 15%;
    right: 15%;
  }

  .leased #marin-badge {
    bottom: unset;
    right: 20vw;
    top: 490px;
    width: 28vw;
  }

  .leased #nc-badge {
    bottom: unset;
    right: 50vw;
    top: 350px;
    width: 18vw;
  }
  @media only screen and (min-height: 1275px) {

    .leased {
      background-position-y: 330px;
      height: 805px;
    }

    #about-p1 {
     top: 250px;
    }

    .worker-imgs {
      top: 200px;
    }

    .about-title {
      bottom: 360px;
    }

    #about-p2 {
      bottom: 175px;
      left: 18%;
      right: 18%;
    }

    .leased #marin-badge {
      top: 675px;
    }
  
    .leased #nc-badge {
      top: 530px;
    }
  }
}

@media only screen and (min-width: 765px) {
  .leased {
    background-position-y: 170px;
    height: 550px;
  }

  .leased #marin-badge {
    top: 445px;
  }

  .leased #nc-badge {
    top: 300px;
  }
}

@media only screen and (min-width: 795px) {
  @media only screen and (min-height: 1075px) {
    .leased {
      background-position-y: 200px;
      height: 590px;
    }

    .leased #marin-badge {
      bottom: 10px;
      top: unset;
    }

    .leased #nc-badge {
      bottom: 15px;
      top: unset;
      width: 17vw;
    }
  }
  @media only screen and (min-height: 1275px) {
    .leased {
      background-position: bottom;
      height: 800px;
    }

    .about-title {
      bottom: 400px;
    }

    .about-title p {
      font-size: 2.5rem;
    }

    #about-p2 {
      bottom: 200px;
      left: 18%;
      right: 18%;
    }

    .leased #marin-badge {
      bottom: 30px;
    }

    .leased #nc-badge {
      bottom: 40px;
    }
  }
}

@media only screen and (min-width: 810px) {
  #about-p2 {
    left: 20%;
    right: 20%;
  }

  .leased #marin-badge {
    width: 25vw;
  }

  .leased #nc-badge {
    width: 15vw;
  }
}

@media only screen and (min-width: 905px) {
  @media only screen and (min-height: 1075px) {
    #about-p1 {
      left: 22%;
      right: 22%;
    }

    #office-manager {
      margin-left: 100px;
      width: 35vw;
    }


    #work-force {
      width: 50vw;
    }

    .leased {
      background-position-y: 175px;
      height: 625px;
    }

    .about-title {
      bottom: 315px;
      padding-left: 30px;
    }

    #about-p2 {
      bottom: 150px;
      left: 25%;
      right: 25%;
    }

    .leased #marin-badge {
      bottom: 10px;
    }

    .leased #nc-badge {
      bottom: 15px;
    }
  }
}


@media only screen and (min-width: 960px) {
  .about-title {
    top: 250px;
    left: 23%;
  }

  .about-title p {
    font-size: 3rem;
  }

  .leased {
    background-position: bottom 0px right 100px;
    transform: translateX(50vw);
  }

  #about-p1 {
    border-radius: 30px;
    top: unset;
    bottom: 210px;
    left: 48%;
    right: 18%;
  }

  #about-p2 {
    border-radius: 30px;
    bottom: 50px;
    left: 48%;
    right: 18%;
  }

  #office-manager {
    position: absolute;
    top: -10px;
    width: 20vw;
  }

  #work-force {
    position: absolute;
    left: 200px;
    top: 10px;
    width: 35vw;
  }

  .leased #marin-badge {
    bottom: 225px;
    left: 340px;
    right: unset;
    top: unset;
    width: 15vw;
  }

  .leased #nc-badge {
    bottom: 65px;
    left: 355px;
    padding-top: unset;
    right: unset;
    top: unset;
    width: 12vw;
  }

  @media only screen and (min-height: 600px) {
    .leased #marin-badge {
      left: 325px;
    }
  
    .leased #nc-badge {
      left: 340px;
    }
  }

  @media only screen and (min-height: 805px) {
    .leased {
      height: 570px;
    }

    .leased #marin-badge {
      left: 350px;
    }
  
    .leased #nc-badge {
      left: 365px;
    }
  }

  @media only screen and (min-height: 905px) {
    .leased {
      height: 675px;
    }

    .leased #marin-badge {
      bottom: 320px;
      left: 325px;
      width: 20vw;
    }
  
    .leased #nc-badge {
      bottom: 120px;
      left: 360px;
      width: 14vw;
    }
  }
}

@media only screen and (min-width: 1275px) {
  @media only screen and (min-height: 650px) {
    .about-title {
      bottom: unset;
      left: 27%;
      top: 275px;
    }

    #office-manager {
      top: -40px;
    }

    #work-force {
      position: absolute;
      left: 225px;
      top: 20px;
      width: 35vw;
    }

    #about-p1 {
      left: 48%;
      right: 20%;
    }
  
    #about-p2 {
      left: 48%;
      right: 20%;
    }

    .leased {
      background-position: bottom -120px right 150px;
    }

    .leased #marin-badge {
      bottom: 320px;
      left: 365px;
      width: 20vw;
    }
  
    .leased #nc-badge {
      bottom: 120px;
      left: 410px;
      width: 14vw;
    }
  }

  @media only screen and (min-height: 860px) {
    .leased {
      height: 655px;
    }

    #about-p1 {
      left: 48%;
      right: 25%;
    }
  
    #about-p2 {
      left: 48%;
      right: 25%;
    }
  }

  @media only screen and (min-height: 1040px) {
    .leased {
      height: 800px;
    }

    .leased #marin-badge {
      left: 465px;
    }
  
    .leased #nc-badge {
      bottom: 80px;
      left: 510px;
    }
  }
}

@media only screen and (min-width: 1675px) {
  @media only screen and (min-height: 910px) {
    .about-title {
      top: 300px;
    }

    .about-title p {
      font-size: 4rem;
    }


    .leased {
      height: 825px;
    }

    #office-manager {
      top: 30px;
    }
  
    #work-force {
      left: 295px;
      top: 140px;
      width: 35vw;
    }

    #about-p1, #about-p2 {
      font-size: 1.7rem;
      padding: 30px;
      right: 20%;
    }

    #about-p1 {
      bottom: 360px;
    }
  
    #about-p2 {
      bottom: 100px;
    }

    .leased #marin-badge {
      bottom: 450px;
      left: 575px;
      width: 15vw;
    }
  
    .leased #nc-badge {
      bottom: 175px;
      left: 610px;
      width: 11vw;
    }
  }
}

@media only screen and (min-width: 1780px) {
  @media only screen and (min-height: 1070px) {
    .leased {
      background-position: bottom -120px right 160px;
      height: 850px;
    }
  }

  @media only screen and (min-height: 1110px) {
    .leased {
      height: 875px;
    }
  }
}

@media only screen and (min-width: 2550px) {
  .about-title {
    top: 350px;
  }

  .about-title p {
    font-size: 5rem;
  }

  #work-force {
    left: 400px;
    top: 185px;
  }

  .leased {
    background-position: bottom -160px right 250px;
    height: 1160px;
  }

  #about-p1, #about-p2 {
    font-size: 2.1rem;
    padding: 30px;
    right: 21%;
  }

  .leased #marin-badge {
    left: 780px;
  }

  .leased #nc-badge {
    bottom: 150px;
    left: 825px;
  }
}
