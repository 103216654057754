#contact-page {
  background-color: #eae7d8;
}

#contact-page span {
  background-color: #f3b33b;
}

#map-and-contact {
  display: flex;
  flex-direction: column;
}

#contact-form {
  width: 90vw;
  padding: 10px 0;
}

#contact-submit {
  background-color: #f3b33b;
}

@media only screen and (min-width: 1020px) {
  #contact-page {
  }

  #contact-page span {
  }

  #map-and-contact {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  #contact-form {
    padding: 50px 0;
    width: 40vw;
  }

  #contact-submit {
  }
}

@media only screen and (min-width: 1900px) {
  #contact-page {
    height: 100vh;
  }
}
