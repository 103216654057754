#careers-page {
  position: relative;
}

.careers-pics {
  width: 100%;
}

#careers-montage {
  width: 100%;
}

#sunset-bldg {
  width: 100%;
  margin-bottom: -10px;
  margin-top: -10px;
}

#join-text {
  bottom: 300px;
  left: 2%;
  position: absolute;
  right: 2%;
  width: 80%;
}

#join-text p {
  color: white;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
}

#career-list {
  background-color: #eae7d8;
  border: 12px solid black;
  border-radius: 30px;
  bottom: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  padding: 10px 10px;
  position: absolute;
  width: 100%;
}

#career-list > p {
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1;
  margin: 0;
  padding-left: 10px;
}

@media only screen and (min-width: 350px) {
  @media only screen and (min-height: 770px) {
    #sunset-bldg {
      height: 36vh;
    }

    #join-text {
      bottom: 350px;
    }
  }

  @media only screen and (min-height: 805px) {
    #join-text {
      bottom: 380px;
    }
  }
}

@media only screen and (min-width: 405px) {
  @media only screen and (min-height: 725px) {
    #join-text {
      bottom: 370px;
    }
  }

  @media only screen and (min-height: 885px) {
    #join-text {
      bottom: 440px;
    }

    #sunset-bldg {
      height: 38vh;
    }

    #career-list {
      bottom: 90px;
    }
  }
}

@media only screen and (min-width: 470px) {
  @media only screen and (min-height: 845px) {
    #join-text {
      bottom: 430px;
      left: 10%;
      right: 10%;
    }

    #career-list {
      left: 1%;
      right: 1%;
      width: 95%;
    }

    #career-list > p {
      line-height: 1.2;
    }
  }
}

@media only screen and (min-width: 590px) {
  @media only screen and (min-height: 350px) {
    .careers-pics {
      display: flex;
      flex-direction: row-reverse;
      overflow: hidden;
    }

    #careers-montage {
      transform: translateX(70px);
      width: 50%;
    }

    #sunset-bldg {
      transform: translateX(70px);
      width: 65%;
      z-index: -1;
    }

    #join-text {
      bottom: 180px;
      left: 1%;
      right: 1%;
    }

    #join-text p {
      font-size: 1.8rem;
    }

    #career-list {
      border: 5px solid black;
      border-radius: 20px;
      box-shadow: 7px 15px black;
      left: 1%;
      right: 1%;
      width: 82%;
    }

    #career-list p {
      font-size: 1.143rem;
    }
  }

  @media only screen and (min-height: 950px) {
    .careers-pics {
      flex-direction: column;
    }

    #careers-montage {
      transform: unset;
      width: 100%;
    }

    #sunset-bldg {
      transform: unset;
      width: 100%;
    }

    #join-text {
      bottom: 525px;
    }

    #join-text p {
      font-size: 3rem;
    }

    #career-list p {
      font-size: 1.5rem;
    }
  }
}
@media only screen and (min-width: 725px) {
  @media only screen and (min-height: 405px) {
    #join-text {
      bottom: 225px;
    }

    #career-list {
      bottom: 50px;
      width: 70%;
    }
  }
}

@media only screen and (min-width: 710px) {
  @media only screen and (min-height: 350px) {
    #join-text {
      bottom: 250px;
    }

    #join-text p {
      font-size: 1.9rem;
    }

    #career-list {
      bottom: 70px;
      width: 70%;
    }
  }

  @media only screen and (min-height: 405px) {
    #join-text {
      bottom: 215px;
    }
  }

  @media only screen and (min-height: 1015px) {
    #careers-montage {
      height: 65vh;
      transform: translateY(-125px);
    }

    #sunset-bldg {
      height: 50vh;
      transform: translateY(-300px);
    }

    .careers-pics {
      height: 80vh;
      overflow: hidden;
    }

    #join-text {
      bottom: 550px;
    }

    #join-text p {
      font-size: 3.2rem;
    }

    #career-list {
      bottom: 200px;
    }

    #career-list p {
      font-size: 1.6rem;
    }
  }


  @media only screen and (min-height: 1270px) {
    .careers-pics {
      height: 85vh;
    }

    #careers-montage {
      height: 50vh;
      transform: unset;
    }

    #sunset-bldg {
      height: 80vh;
      transform: translateY(-10px);
    }

    #join-text {
      bottom: 650px;
    }

    #join-text p {
      font-size: 4rem;
    }

    #career-list {
      bottom: 250px;
      width: 80%;
    }

    #career-list p {
      font-size: 1.9rem;
    }
  }
}

@media only screen and (min-width: 805px) {
  @media only screen and (min-height: 405px) {
    #join-text {
      bottom: 275px;
    }

    #join-text p {
      font-size: 2rem;
    }

    #career-list {
      width: 70%;
    }

    #career-list p {
      font-size: 1.4rem;
    }
  }

  @media only screen and (min-height: 1070px) {
    #join-text {
      bottom: 525px;
    }

    #join-text p {
      font-size: 4rem;
    }

    #career-list p {
      font-size: 1.9rem;
    }
  }
}

@media only screen and (min-width: 950px) {
  @media only screen and (min-height: 590px) {
    #join-text {
      bottom: 315px;
    }

    #join-text p {
      font-size: 2.3rem;
    }

    #career-list {
      bottom: 100px;
      width: 70%;
    }
  }
}

@media only screen and (min-width: 1015px) {
  @media only screen and (min-height: 755px) {
    #join-text {
      bottom: 375px;
      width: 100%;
    }

    #join-text p {
      font-size: 2.8rem;
    }

    #careers-montage {
      height: 71vh;
      transform: translateX(220px);
      width: unset;
    }

    #sunset-bldg {
      height: 73vh;
      transform: translateX(475px);
      width: unset;
    }

    #career-list {
      bottom: 175px;
      padding-left: 40px;
    }
  }

  @media only screen and (min-height: 1270px) {
    .careers-pics {
      height: 82vh;
    }

    #careers-montage {
      transform: translate(0px, -350px);
      width: 100%;
    }

    #sunset-bldg {
      transform: translate(0px, -450px);
      width: 100%;
    }

    #join-text {
      bottom: 650px;
    }

    #join-text p {
      font-size: 4.6rem;
    }

    #career-list {
      bottom: 250px;

    }
  }
}

@media only screen and (min-width: 1070px) {
  @media only screen and (min-height: 710px) {
    #careers-montage {
      transform: translateX(90px);
    }

    #sunset-bldg {
      transform: translateX(160px);
    } 
    
    #join-text {
      bottom: 400px;
    }

    #join-text p {
      font-size: 3.1rem;
    }

    #career-list {
      bottom: 200px;
      width: 60%;
    }
  }

  @media only screen and (min-height: 800px) {
    #careers-montage {
      transform: translateX(225px);
    }

    #sunset-bldg {
      transform: translateX(275px);
    } 
  }

  @media only screen and (min-height: 900px) {
    .careers-pics {
      height: 75vh;

    }

    #careers-montage {
      height: 75vh;
      transform: translateX(350px);
    }

    #sunset-bldg {
      height: 77vh;
      transform: translateX(475px);
    }

    #join-text {
      bottom: 480px;
      width: 100%;
    }

    #career-list {
      bottom: 250px;
    }
  }
}

@media only screen and (min-width: 1270px) {
  @media only screen and (min-height: 650px) {
    #join-text {
      bottom: 350px;
      width: 100%;
    }

    #join-text p {
      font-size: 3.5rem
    }

    #careers-montage {
      transform: translateX(110px);
    }

    #sunset-bldg {
      transform: translateX(125px);
    }

    #career-list {
      bottom: 150px;
      width: 50%;
    }
  }

  @media only screen and (min-height: 790px) {
    #join-text {
      bottom: 400px;
    }

    #career-list {
      bottom: 175px;
    }
  }

  @media only screen and (min-height: 1015px) {
    .careers-pics {
      flex-direction: row-reverse;
      height: 76vh;
    }

    #careers-montage {
      height: 78vh;

      transform: translateX(375px);
    }

    #sunset-bldg {
      height: 78vh;
      transform: translateX(450px);
    }

    #join-text {
      bottom: 550px;
    }

    #career-list {
      bottom: 275px;
    }
  }
}

@media only screen and (min-width: 1525px) {
  @media only screen and (min-height: 855px) {
    .careers-pics {
      flex-direction: row-reverse;
      height: 74vh;
    }

    #join-text {
      bottom: 480px;
    }

    #careers-montage {
      height: 77vh;
    }

    #sunset-bldg {
      height: 76vh;
    }
  }

  @media only screen and (min-height: 1040px) {
    .careers-pics {
      height: 78vh;
    }

    #join-text {
      bottom: 580px;
    }

    #careers-montage {
      height: 77vh;
      transform: translateX(200px);
    }

    #sunset-bldg {
      height: 76vh;
      transform: translateX(200px);
    }

    #career-list p {
      font-size: 2rem;
    }
  }
}

@media only screen and (min-width: 1780px) {
  @media only screen and (min-height: 1110px) {
    #join-text {
      bottom: 650px;
    }

    #join-text p {
      font-size: 4.3rem;
    }

    #sunset-bldg {
      height: 78vh;
    }

    #career-list {
      padding: 20px 70px 10px;
      box-shadow: 17px 25px black;
      width: 50%;
    }
  }
}

@media only screen and (min-width: 1910px) {
  @media only screen and (min-height: 905px) {
    #join-text {
      bottom: 550px;
    }

    .careers-pics {
      height: 76vh;
    }

    #sunset-bldg {
      height: 78vh;
      transform: unset;
    }

    #careers-montage {
      height: 80vh;
      transform: unset;
    }

    #career-list {
      border: 7px solid black;
      box-shadow: 15px 23px black;
      width: 50%;
    }

    #career-list p {
      font-size: 1.9rem;
    }
  }

  @media only screen and (min-height: 1070px) {
    #sunset-bldg {
      transform: translateX(200px);
    }

    #careers-montage {
      transform: translateX(150px);
    }
  }
}

@media only screen and (min-width: 2550px) {
  @media only screen and (min-height: 1430px) {
    .careers-pics {
      height: 81vh;
    }

    #sunset-bldg {
      height: 82vh;
      transform: translateX(200px);
    }

    #careers-montage {
      height: 82vh;
      transform: translateX(200px);
    }

    #join-text {
      bottom: 850px;
    }

    #join-text p {
      font-size: 6rem;
    }

    #career-list {
      border: 15px solid black;
      border-radius: 50px;
      bottom: 350px;
      box-shadow: 20px 30px black;
      padding: 30px 100px;
      width: 55%
    }

    #career-list p {
      font-size: 3rem;
    }

  }
}