#services-page {
  background-color: #e7e7e7;
}

#services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  text-align: center;
}

.services-pics {
  position: relative;
}

.services-pics img {
  width: 140px;
  margin: 10px;
}

.services-pics p {
  color: white;
  font-size: 1.4rem;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 25%;
}

#services-p-border {
  background-color: #448bca;
  border-style: solid;
  border-width: thin;
  color: white;
  font-size: 1.2rem;
  grid-area: 1/1/2/3;
  height: max-content;
  padding: 5px;
}

#services-p-border p {
  border-width: thin;
  border-style: solid;
  margin: 1px;
  padding: 20px 30px;
}

#health-care {
  grid-area: 2/1/3/2;
}

#health-care p {
  top: 40%;
}

#steel-frame p {
  top: 30%;
}

#drywall p {
  top: 35%;
}

#insulation p {
  top: 30%;
}

#demolition p {
  top: 35%;
}

#exteriors p {
  top: 35%;
}

#improvement p {
  top: 25%;
}

#relocation p {
  top: 25%;
}

#shells {
  grid-area: 6/1/7/3;
}

#shells img {
  width: 200px;
}

#shells p {
  top: 25%;
}

@media only screen and (min-width: 765px) {
  #services-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: fit-content(10%);
  }

  #services-container img {
    width: 70%;
  }

  #services-p-border {
    grid-area: 1/1/2/4;
  }

  #services-p-border p {
    font-size: 1.6rem;
  }

  .services-pics {
    align-self: center;
  }

  .services-pics p {
    font-size: 1.6rem;
  }

  #shells {
    grid-area: 4/3/5/4;
  }

  #shells p {
    margin-top: -5px;
  }
}

@media only screen and (min-width: 1020px) {
  .services-pics p {
    font-size: 2rem;
  }

  #services-p-border {
    width: 80vw;
  }

  #services-p-border p {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1435px) {
  #services-container {
    grid-template-columns: repeat(4, 1fr);
    width: 80vw;
  }

  #services-p-border {
    grid-area: 1/1/2/5;
    padding: 10px;
    width: 75vw;
  }

  #services-p-border p {
    font-size: 2.3rem;
  }

  #shells {
    grid-area: 4/2/5/4;
  }

  #shells {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
