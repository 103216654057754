#recycling-page {
  background-color: #fff799;
  height: auto;
  position: relative;
}

#background-pic-container {
  background-image: url("https://res.cloudinary.com/tca7lsohuid8/image/upload/v1648439671/lcr_website/Recycling_Debris_Boxes_r2z86r.png");
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 66vh;
  width: 100%;
}

#recycling-paragraph, #resource-links, #county-links {
  background-color: #9CBB6A;
  border: 5px solid #196537;
  border-radius: 20px;
  font-size: 1.143rem;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  text-align: center;
}

#resource-links a:hover, #county-links a:hover {
  text-shadow: 3px 4px 4px rgb(28, 111, 27);
  transition: ease-in 100ms;
}

#resource-links {
  bottom: 295px;
  left: 5%;
  right: 5%;
  z-index: 1;
}

#resource-links a {
  color: black;
  display: flex;
  flex-direction: column;
  text-shadow: 1px 1px 1px #196537;
}

#recycling-paragraph {
  bottom: 80px;
  left: 5%;
  right: 5%;
  z-index: 1;
}

#earth-pic {
  bottom: 60px;
  left: 2%;
  opacity: 1;
  position: absolute;
  right: 2%;
  width: 95vw;
}

#recycle-icon {
  bottom: 40px;
  left: 1%;
  opacity: 0.1;
  position: absolute;
  right: 1%;
  width: 95vw;
  z-index: 2;
}

#county-links {
  left: 5%;
  right: 5%;
  bottom: 2%;
  display: flex;
  flex-direction: row;
}

#county-links a {
  color: black;
  text-shadow: 1px 1px 1px #196537;
}

@media only screen and (min-height: 660px) {
  #background-pic-container {
    height: 80vh;
  }

  #resource-links {
    bottom: 400px;
  }
  

  #recycling-paragraph {
    bottom: 150px;
  }
  
  #earth-pic {
    bottom: 100px;
  }
  
  #recycle-icon {
    bottom: 80px;
  }
  
  #county-links {
    bottom: 50px;
  }
}

@media only screen and (min-height: 770px) {
  #resource-links {
    bottom: 490px;
  }

  #recycling-paragraph {
    bottom: 120px;
    font-size: 1.5rem;
    left: 15%;
    right: 15%;
  }

  #recycling-paragraph p {
    font-size: 1.3rem;
  }

  #earth-pic {
    bottom: 130px;
    opacity: 0.7;
  }
  
  #recycle-icon {
    bottom: 130px;
    opacity: 0.13;
  }
}

@media only screen and (min-width: 405px) {
  @media only screen and (min-height: 725px) {
    #resource-links {
      bottom: 445px;
      z-index: 2;
    }

    #recycling-paragraph {
      left: 15%;
      right: 15%;
      z-index: 2;
    }

    #recycle-icon {
      opacity: 0.5;
 
    }
  }

  @media only screen and (min-height: 805px) {
    #resource-links {
      bottom: 500px;
    }

    #recycling-paragraph {
      bottom: 135px;
    }
  }

  @media only screen and (min-height: 885px) {
    #resource-links {
      bottom: 550px;
    }

    #recycling-paragraph {
      bottom: 175px;
    }
  }

  @media only screen and (min-height: 905px) {
    #resource-links {
      bottom: 575px;
    }
  
    #recycling-paragraph {
      bottom: 180px;
    }
  
    #earth-pic {
      bottom: 150px;
    }
    
    #recycle-icon {
      bottom: 150px;
    }
  }
}

@media only screen and (min-width: 470px) {
  #resource-links {
    bottom: 525px;
  }

  #recycling-paragraph {
    bottom: 200px;
  }
}

@media only screen and (min-width: 590px) {
  @media only screen and (min-height: 350px) {
    #resource-links {
      bottom: 75px;
      left: 5%;
      right: 60%;
    }

    #earth-pic {
      bottom: 10px;
      width: 25%;
    }

    #recycle-icon {
      bottom: 10px;
      width: 25%;
    }

    #recycling-paragraph {
      bottom: 15px;
      left: 45%;
      right: 1%;
    }

    #county-links {
      right: 60%;
    }
  }

  @media only screen and (min-height: 950px) {
    #background-pic-container {
      height: 81vh;
    }

    #resource-links {
      bottom: 600px;
      left: 15%;
      right: 15%;
    }

    #recycling-paragraph {
      bottom: 300px;
      left: 15%;
      right: 15%;
    }

    #earth-pic {
      bottom: 140px;
      width: 80%
    }
    
    #recycle-icon {
      bottom: 140px;
      width: 80%
    }

    #county-links {
      bottom: 75px;
      left: 15%;
      right: 15%;
    }
  }

  @media only screen and (min-height: 1015px) {
    #resource-links {
      bottom: 650px;
    }
  }
}

@media only screen and (min-width: 710px) {
  @media only screen and (min-height: 420px) {
    #resource-links {
      bottom: 125px;
    }

    #recycling-paragraph {
      bottom: 65px;
    }

    #recycle-icon {
      bottom: 45px;
    }
  
    #earth-pic {
      bottom: 45px;
    }

    #county-links {
      bottom: 50px;
    }
  }

  @media only screen and (min-height: 1015px) {
    #resource-links {
      bottom: unset;
      left: 10%;
      right: 50%;
      top: 300px;
    }
  
    #recycling-paragraph {
      bottom: 250px;
      left: 25%;
      right: 25%;
    }
  
    #county-links {
      bottom: 100px;
      left: 55%;
      right: 10%;
      z-index: 2;
    }
  
  }

  @media only screen and (min-height: 1070px) {
    #recycle-icon {
      bottom: 100px;
    }
  
    #earth-pic {
      bottom: 100px;

    }
  }

  @media only screen and (min-height: 1270px) {
    #background-pic-container {
      height: 83vh;
    }

    #recycling-paragraph {
      bottom: 400px;
    }
  
    #recycle-icon {
      bottom: 200px;
    }
  
    #earth-pic {
      bottom: 215px;
      opacity: 0.8;
    }
  
    #resource-links {
      bottom: 800px;
      left: 20%;
      right: 20%;
    }
  
    #county-links {
      bottom: 75px;
      left: 25%;
      right: 25%;
    }
  }
  @media only screen and (min-height: 1355px) {
    #background-pic-container {
      height: 84vh;
    }

  }
}


@media only screen and (min-width: 950px) {
  #background-pic-container {
    width: 45%;
  }

  #recycling-paragraph {
    bottom: 50px;
    left: 5%;
    padding: 10px;
    right: 70%;
  }

  #recycle-icon {
    opacity: 1;
    width: 38%;
  }

  #earth-pic {
    bottom: 90px;
    opacity: 0.8;
    width: 20%;
  }

  #resource-links {
    bottom: 200px;
    left: 70%;
    right: 2%;
  }

  #county-links {
    left: 70%;
    right: 2%;
  }

  @media only screen and (min-height: 590px) {
    #background-pic-container {
      height: 69vh;
    }

    #recycling-paragraph {
      bottom: 60px;
    }
  
    #recycle-icon {
      bottom: 20px;
    }
  
    #earth-pic {
      bottom: 120px;
    }
  
    #resource-links {
      bottom: 200px;
    }
  
    #county-links {
      bottom: 80px;
    }
  }

  @media only screen and (min-height: 800px) {
    #background-pic-container {
      height: 71vh;
    }

    #recycling-paragraph {
      bottom: 110px;
    }
  
    #recycle-icon {
      bottom: 80px;
    }
  
    #earth-pic {
      bottom: 180px;
    }
  
    #resource-links {
      bottom: 325px;
    }
  
    #county-links {
      bottom: 150px;
    }
  }

  @media only screen and (min-height: 900px) {
    #background-pic-container {
      background-image: url("https://res.cloudinary.com/tca7lsohuid8/image/upload/v1648439671/lcr_website/Recycling_Debris_Boxes_r2z86r.png");
      background-position: 0 50%;
      background-repeat: no-repeat;
      height: 74vh;
      width: 60%;
    }

    #recycling-paragraph {
      bottom: 175px;
      left: 2%;
      right: 75%;
    }

    #recycle-icon {
      bottom: 65px;
      width: 50vw;
    }
  
    #earth-pic {
      bottom: 220px;
    }
  
    #resource-links {
      bottom: 425px;
    }
  
    #county-links {
      bottom: 175px;
      left: 75%;
      right: 2%;
      z-index: 2;
    }
  

  }

  @media only screen and (min-height: 1270px) {
    #background-pic-container {
      height: 82vh;
    }

    #recycling-paragraph {
      bottom: 125px;
    }
  
    #recycle-icon {
      bottom: 325px;
    }
  
    #earth-pic {
      bottom: 420px;
    }
  
    #resource-links {
      bottom: 700px;
    }
  
    #county-links {
      bottom: 300px;
    }
  }
}

@media only screen and (min-width: 1015px) {
  @media only screen and (min-height: 500px) {
    #recycle-icon {
      width: 31vw;
    }

    #recycling-paragraph {
      left: 10%;
    }
  }

  @media only screen and (min-height: 755px) {
    #recycling-paragraph {
      bottom: 100px;
      left: 10%;
      right: 68%;
      z-index: 1;
    }
  
    #recycle-icon {
      bottom: 65px;
    }
  
    #earth-pic {
      bottom: 150px;
    }
  
    #resource-links {
      bottom: 300px;
      left: 65%;
      right: 10%;
    }
  
    #county-links {
      bottom: 125px;
      left: 70%;
      right: 8%;
    }
  }

  @media only screen and (min-height: 910px) {
    #recycling-paragraph {
      left: 5%;
    }

    #recycle-icon {
      bottom: 150px;
    }
  
    #earth-pic {
      bottom: 225px;
    }

    #resource-links {
      bottom: 400px;
    }
  
    #county-links {
      bottom: 225px;
      right: 5%;
      left: 73%;
    }
  }

  @media only screen and (min-height: 1015px) {
    #background-pic-container {
      height: 76vh;
    }

    #recycling-paragraph {
      bottom: 150px;
      left: 10%;
    }

  
    #resource-links {
      bottom: 500px;
      padding: 15px;
      top: unset;
    }
  }
}

@media only screen and (min-width: 1355px) {
  @media only screen and (min-height: 755px) {
    #recycling-paragraph {
      bottom: 75px;
      left: 10%;
    }

    #recycling-paragraph p {
      font-size: 1.4rem;
    }

    #recycle-icon {
      bottom: 15px;
    }
  
    #earth-pic {
      bottom: 125px;
    }
  
    #resource-links {
      bottom: 300px;
    }
  
    #county-links {
      bottom: 125px;
    }
  }

  @media only screen and (min-height: 890px) {
    #recycling-paragraph {
      bottom: 150px;
      left: 10%;
      right: 70%;
    }

    #recycle-icon {
      bottom: 10px;
      width: 42vw;
    }

    #earth-pic {
      bottom: 175px;
    }

    #resource-links {
      bottom: 400px;      
      left: 67%;
      right: 8%;
    }

    #county-links {
      bottom: 175px;
      left: 72%;
      right: 8%;
    }
  }
}

@media only screen and (min-width: 1525px) {
  @media only screen and (min-height: 855px) {
    #background-pic-container {
      height: 72vh;
    }
  }

  @media only screen and (min-height: 890px) {
    #background-pic-container {
      height: 73vh;
      width: 50%;
    }

    #recycling-paragraph {
      bottom: 125px;
      left: 18%;
      right: 67%;
    }

    #recycle-icon {
      width: 35vw;
    }

    #earth-pic {
      bottom: 150px;
    }

    #county-links {
      bottom: 175px;
      left: 70%;
      right: 13%;
    }
  }


  @media only screen and (min-height: 1040px) {
    #background-pic-container {
      height: 75vh;
    }


    #recycling-paragraph {
      bottom: 125px;
      left: 15%;
    }

    #recycling-paragraph p, #resource-links, #county-links  {
      font-size: 1.5rem;
      line-height: 1.3;
    }

    #recycle-icon {
      bottom: 65px;
    }

    #earth-pic {
      bottom: 225px;
    }

    #resource-links {
      bottom: 475px;
      padding: 15px;
    }

    #county-links {
      bottom: 200px;
      left: 68%;
      padding: 20px;
      right: 8%;
    }
  }

  @media only screen and (min-height: 1070px) {
    #background-pic-container {
      height: 77vh;
    }

    #recycling-paragraph {
      bottom: 200px;
    }

    #county-links {
      right: 10%;
    }
  }
}

@media only screen and (min-width: 1910px) {
  @media only screen and (min-height: 905px) {
    #recycling-paragraph p, #resource-links, #county-links  {
      font-size: 1.8rem;
    }

    #resource-links, #county-links {
      line-height: 1.2;
    }

    #recycling-paragraph {
      bottom: 70px;
    }

    #recycle-icon {
      bottom: 75px;
      width: 30%;
    }

    #earth-pic {
      bottom: 180px;
    }

    #county-links {
      left: 70%;
      padding: 20px;
      right: 8%
    }
  }
}

@media only screen and (min-width: 2550px) {
  @media only screen and (min-height: 1430px) {
    #background-pic-container {
      height: 81vh;
    }

    #recycling-paragraph p, #resource-links, #county-links  {
      font-size: 2.8rem;
      padding: 20px;
    }

    #resource-links {
      bottom: 600px;
    }

    #resource-links a {
      line-height: 1.4;
    }

    #county-links {
      bottom: 300px;
      left: 68%;
      right: 6%;
      line-height: 1.4;
    }

    #earth-pic {
      bottom: 300px;
    }


    #recycle-icon {
      bottom: 180px;
    }
  }
}