#logo {
  background-color: transparent;
  padding: 40px 0 10px;
  text-align: center;
}

#logo img {
  width: 150px;
}

@media only screen and (min-width: 1020px) {
  #logo img {
    width: 175px;
  }
}

@media only screen and (min-width: 1275px) {
  #logo {
    padding: 20px 0 10px;
  }
  #logo img {
    width: 225px;
  }
}
