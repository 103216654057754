#homepage-pic {
  background-image: url("https://res.cloudinary.com/tca7lsohuid8/image/upload/v1642563116/lcr_website/Plan_Desk_with_Border_ozfw9n.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 245px;
  margin-bottom: 30px;
  width: 95vw;
}

#background-container {
  background-image: url("https://res.cloudinary.com/tca7lsohuid8/image/upload/v1642372247/lcr_website/Bank2_inverted_a6b2xh.png");
  background-position: -160px 0px;
  background-repeat: no-repeat;
  background-size: 145%;
  height: 349px;
}

.animated > h3 {
  color: white;
  font-size: 2.5rem;
  padding-left: 5px;
  text-shadow: 1px 1px 1px rgb(59, 51, 51);
}

#marin-badge {
  left: 100px;
  position: absolute;
  width: 220px;
}

#nc-badge {
  left: 120px;
  padding-top: 160px;
  position: absolute;
  width: 180px;
}

@media only screen and (min-width: 315px) {
  #background-container {
    background-position: -290px center;
    background-size: 230%;
    height: 486px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 389px) {
  #homepage-pic {
    height: 280px;
  }

  .animated > h3 {
    font-size: 3rem;
  }

  h3#strategy {
    padding-top: 10px;
  }

  #background-container {
    background-size: 187%;
  }

  #marin-badge {
    left: 46vw;
    margin-top: -10px;
    width: 210px;
  }

  #nc-badge {
    left: 49vw;
    padding-top: 130px;
    width: 180px;
  }
}

@media only screen and (min-width: 475px) {
  #homepage-pic {
    height: 320px;
  }

  #background-container {
    background-position: -300px -80px;
  }
}

@media only screen and (min-width: 590px) {
  #homepage-pic {
    height: 400px;
  }

  #background-container {
    background-position: -360px -130px;
    background-size: 170%;
    height: 550px;
  }

  .animated > h3 {
    font-size: 4rem;
  }

  #marin-badge {
    left: 50vw;
    margin-top: -50px;
    width: 260px;
  }

  #nc-badge {
    left: 53vw;
    padding-top: 110px;
    width: 220px;
  }
}

@media only screen and (min-width: 635px) {
  #homepage-pic {
    background-position: center;
    background-size: 95%;
  }
}

@media only screen and (min-width: 735px) {
  @media only screen and (min-height: 355px) {
    .animated > h3 {
      font-size: 3.5rem;
    }

    #homepage-pic {
      position: absolute;
      bottom: -220px;
      right: 10px;
      max-width: 400px;
      max-height: 250px;
    }

    #background-container {
      background-image: url("https://res.cloudinary.com/tca7lsohuid8/image/upload/v1635617249/lcr_website/Bank_he0sgm.png");
      background-position: center 5px;
      background-size: 140%;
    }

    h3#strategy {
      padding-top: 40px;
    }

    #marin-badge {
      left: 125px;
      bottom: -170px;
      width: 175px;
    }

    #nc-badge {
      left: 154px;
      bottom: -300px;
      width: 120px;
    }
  }
  @media only screen and (min-height: 410px) {
    #background-container {
      background-size: 148%;
    }

    #homepage-pic {
      max-width: 375px;
      bottom: -200px;
    }

    #marin-badge {
      left: 90px;
      top: 470px;
    }

    #nc-badge {
      left: 115px;
      top: 440px;
    }

    @media only screen and (min-height: 735px) {
      #homepage-pic {
        top: 200px;
      }
    }
  }
  @media only screen and (min-height: 1020px) {
    .animated > h3 {
      font-size: 4rem;
      text-shadow: 2px 3px 3px rgb(59, 51, 51);
    }

    #homepage-pic {
      max-width: 500px;
      background-position: center -5px;
      max-height: 325px;
      top: 180px;
      left: 20px;
    }

    #background-container {
      position: absolute;
      bottom: 30px;
      background-position: -200px -80px;
      background-size: 142%;
      width: 100%;
      max-height: 465px;
    }

    h3#strategy {
      padding-top: 25px;
    }

    #marin-badge {
      right: -250px;
      top: 120px;
      width: 250px;
    }

    #nc-badge {
      right: -230px;
      top: 135px;
      width: 210px;
    }
  }

  @media only screen and (min-height: 1275px) {
    #homepage-pic {
      max-height: 400px;
      max-width: 600px;
    }

    #background-container {
      bottom: 0px;
      background-position: -300px -155px;
      background-size: 201%;
      height: 650px;
      max-height: 100vh;
    }

    .animated > h3 {
      font-size: 4.5rem;
    }

    #marin-badge {
      top: 225px;
    }

    #nc-badge {
      top: 250px;
    }
  }
}

@media only screen and (min-width: 850px) {
  #homepage-pic {
    bottom: -200px;
    max-height: 500px;
    max-width: 500px;
  }

  #background-container {
    background-size: 130%;
  }

  #marin-badge {
    left: 125px;
  }

  #nc-badge {
    left: 150px;
  }
}

@media only screen and (min-width: 960px) and (min-height: 595px) {
  #homepage-pic {
    bottom: -70px;
  }

  #background-container {
    background-position: center center;
    height: 100vh;
  }

  #marin-badge {
    left: 135px;
    width: 225px;
  }

  #nc-badge {
    left: 162px;
    width: 175px;
    padding-top: 125px;
  }
}

@media only screen and (min-width: 1020px) {
  @media only screen and (max-height: 590px) {
    .animated > h3 {
      font-size: 5rem;
      line-height: 1.6;
      padding-left: 50px;
    }

    h3#strategy {
      padding-top: 175px;
    }

    #homepage-pic {
      position: absolute;
      top: 350px;
    }

    #background-container {
      background-size: 113%;
    }

    #marin-badge {
      left: 500px;
      top: 425px;
    }

    #nc-badge {
      left: 525px;
      top: 450px;
    }
  }

  @media only screen and (min-height: 730px) {
    #homepage-pic {
      max-width: 600px;
      top: 375px;
    }

    #background-container {
      background-size: 145%;
    }

    .animated > h3 {
      font-size: 4rem;
      padding-left: 50px;
    }

    #marin-badge {
      left: 75px;
      top: 525px;
      width: 275px;
    }

    #nc-badge {
      left: 100px;
      top: 560px;
      width: 225px;
    }
  }

  @media only screen and (min-height: 790px) {
    #background-container {
      background-size: 170%;
    }

    #marin-badge {
      left: 180px;
    }

    #nc-badge {
      left: 205px;
    }
  }

  @media only screen and (min-height: 1020px) {
    #homepage-pic {
      max-height: 1200px;
      position: absolute;
      top: 300px;
      right: -600px;
    }

    #background-container {
      background-size: 145%;
      max-height: 80%;
      top: 175px;
      z-index: -1;
    }

    .animated > h3 {
      font-size: 5rem;
      line-height: 1.6;
    }

    h3#strategy {
      padding-top: 50px;
    }

    #marin-badge {
      top: 525px;
      left: -1100px;
    }

    #nc-badge {
      top: 350px;
      left: -520px;
    }
  }
}

@media only screen and (min-width: 1275px) {
  @media only screen and (min-height: 625px) {
    #background-container {
      background-position: center;
      background-size: 110%;
    }

    .animated > h3 {
      font-size: 4.5rem;
      line-height: 1.8;
      padding-left: 50px;
      text-shadow: 2px 2px 1px rgb(59, 51, 51);
    }

    h3#strategy {
      padding-top: 180px;
    }

    #marin-badge {
      top: 425px;
      left: 480px;
      width: 200px;
    }

    #nc-badge {
      top: 450px;
      left: 490px;
      width: 180px;
    }
  }

  @media only screen and (min-height: 1020px) {
    .animated > h3 {
      font-size: 5rem;
    }

    #homepage-pic {
      background-size: 500px;
      left: 100px;
      top: 375px;
      width: 500px;
    }

    #marin-badge {
      top: 225px;
      right: 550px;
      width: 220px;
    }

    #nc-badge {
      top: 300px;
      right: 560px;
    }
  }
}

@media only screen and (min-width: 1275px) {
  @media only screen and (min-height: 715px) {
    #background-container {
      background-size: 125%;
    }
  }
  @media only screen and (min-height: 1020px) {
    #background-container {
      background-size: 130%;
    }
  }
}

@media only screen and (min-width: 1435px) {
  @media only screen and (min-height: 860px) {
    #homepage-pic {
      position: absolute;
      top: 400px;
      right: 50px;
    }

    .animated > h3 {
      font-size: 5rem;
      line-height: 1.6;
    }

    #marin-badge {
      left: 525px;
    }

    #nc-badge {
      left: 540px;
    }
  }
}

@media only screen and (min-width: 1530px) {
  @media only screen and (min-height: 860px) {
    #homepage-pic {
      top: 275px;
    }

    #background-container {
      background-position: center -100px;
      background-size: 105%;
      height: 78vh;
    }

    h3#strategy {
      padding-top: 100px;
    }

    #marin-badge {
      left: 590px;
      top: 325px;
    }

    #nc-badge {
      left: 605px;
      top: 375px;
    }
  }

  @media only screen and (min-height: 895px) {
    .animated > h3 {
      padding-left: 100px;
    }

    #marin-badge {
      left: 665px;
    }

    #nc-badge {
      left: 675px;
    }
  }

  @media only screen and (min-height: 1045px) {
    #background-container {
      background-position: center -80px;
      background-size: 110%;
      height: 100%;
      top: 185px;
    }

    #homepage-pic {
      max-height: 800px;
      max-width: 575px;
      background-size: 100%;
      left: 950px;
      top: 350px;
    }

    .animated > h3 {
      font-size: 6rem;
    }

    h3#strategy {
      padding-top: 150px;
    }

    #marin-badge {
      left: 505px;
      top: 180px;
      width: 275px;
    }

    #nc-badge {
      left: 525px;
      top: 290px;
      width: 225px;
    }
  }
}

@media only screen and (min-width: 1910px) {
  #background-container {
    background-size: 100%;
    background-position: center -189px;
  }

  .animated > h3 {
    font-size: 6.5rem;
  }

  #homepage-pic {
    height: 520px;
    max-width: 750px;
    left: 1130px;
  }

  #marin-badge {
    left: 750px;
    top: 350px;
    width: 275px;
  }

  #nc-badge {
    left: 775px;
    top: 425px;
    width: 225px;
  }

  @media only screen and (min-height: 970px) {
    #background-container {
      height: 78.7vh;
    }
  }

  @media only screen and (min-height: 1075px) {
    #background-container {
      background-size: 110%;
    }

    #marin-badge {
      left: 450px;
      top: 250px;
    }

    #nc-badge {
      left: 475px;
      top: 325px;
    }
  }
}

@media only screen and (min-width: 2555px) {
  .animated > h3 {
    font-size: 8.5rem;
    line-height: 1.8;
    padding-left: 150px;
    text-shadow: 3px 5px 2px rgb(59, 51, 51);
  }

  h3#strategy {
    padding-top: 225px;
  }

  #marin-badge {
    right: 715px;
    top: 300px;
    width: 400px;
  }

  #nc-badge {
    right: 720px;
    top: 475px;
    width: 325px;
  }

  #homepage-pic {
    left: 1400px;
    height: 690px;
    max-width: 1000px;
    top: 400px;
  }

  #background-container {
    background-position: center -115px;
    background-size: 105%;
    max-height: 1225px;
    height: 100vh;
  }
}
