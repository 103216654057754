.footer {
  background-color: #67778a;
  float: bottom;
}

.footer p {
  color: rgb(183, 187, 196);
  font-size: 0.75rem;
  text-align: center;
  padding: 5px 0;
}

@media only screen and (min-width: 765px) {
  .footer {
    background-color: #6c7d8c;
  }

  .footer p {
    padding: 9px 0;
  }

  @media only screen and (min-height: 1020px) {
    .footer {
      bottom: 0;
      position: absolute;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1275px) {
  .footer p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1360px) {
  .footer p {
    padding: 5px 0;
  }
}

@media only screen and (min-width: 1675px) {
  .footer p {
    padding: 10px 0;
  }
}
