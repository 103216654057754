@import url("https://fonts.googleapis.com/css2?family=Coda+Caption:wght@800&family=Crimson+Text&family=Flamenco&family=Roboto+Condensed:wght@300&display=swap");
* {
  margin: 0 auto;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}
